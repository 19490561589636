<template>
  <div>
    <v-subheader v-if="item.header" v-text="item.header" />
    <v-divider v-else-if="item.divider" :inset="item.inset" />
    <v-list-item v-else>
      <v-list-item-avatar>
        <v-img :src="user.image ? user.image : placeholderImage"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title
          v-html="user.name"
          v-if="user.type == 'Organization'"
        />
        <v-list-item-title
          v-html="
            user.first_name + (user.last_name ? ' ' + user.last_name : '')
          "
          v-else
        />
        <v-list-item-subtitle v-html="item.text" />
      </v-list-item-content>
    </v-list-item>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    item: Object,
  },
  data() {
    return {
      placeholderImage: require("@/assets/default.jpg"),
      user: {},
    };
  },
  methods: {
    ...mapActions({
      getClient: "client/getClient",
      getPartner: "partner/getPartner",
      getOrganization: "partner/getOrganization",
      getFreezer: "freezer/getFreezer",
    }),
    loadData() {
      if (this.item.participants.length) {
        for (var i = 0; i < this.item.participants.length; i++) {
          var p = this.item.participants[i];
          if (p._id != this.profile._id || p.type != this.profileType) {
            if (p.type == "Admin" && this.profileType != "Admin") {
              console.log("set admin");
              this.user = { type: "Admin", first_name: "Support" };
            } else if (p.type != "Admin") {
              this.user = p;
            }
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      profile: "auth/getProfile",
      profileType: "auth/getType",
    }),
  },
  watch: {
    item(newValue) {
      if (newValue) {
        this.loadData();
      }
    },
    profileType(newValue) {
      if (newValue) {
        this.loadData();
      }
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
