<template>
  <v-container
    fluid
    style="padding:0px; height: 100%;flex-grow: 1; flex-shrink: 0; display:flex; flex-direction: column;"
  >
    <Menu style="height: 50px;flex-grow:0; background: #DBC7FF" />
    <v-card
      style="flex-grow:1;flex-shrink:0; display:flex;flex-direction:row; height: 100px; min-height: 100px; overflow: hidden;"
    >
      <v-navigation-drawer
        permanent
        left
        :width="$vuetify.breakpoint.smAndDown ? '100%' : 400"
      >
        <v-list three-line>
          <v-list-item v-if="profileType == 'Admin'">
            <v-list-item-content>
              <v-list-item-title>
                <div class="ma-3 d-flex flex-row">
                  <v-autocomplete
                    :disabled="loading || profileType != 'Admin'"
                    :items="users"
                    dense
                    rounded
                    outlined
                    solo
                    background-color="white"
                    label="Select"
                    :filter="searchFilter"
                    @change="onSelectedUser"
                    :error-messages="searchError"
                    append-icon="mdi-magnify"
                    class="notranslate"
                  >
                    <template v-slot:selection="data">
                      {{
                        data.item.first_name +
                          (data.item.last_name ? " " + data.item.last_name : "")
                      }}
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-avatar>
                        <editable-avatar
                          :image="data.item.image"
                          :size="40"
                          :editable="false"
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="
                            data.item.name
                              ? data.item.name
                              : data.item.first_name +
                                (data.item.last_name
                                  ? ' ' + data.item.last_name
                                  : '')
                          "
                        />
                        <v-list-item-subtitle v-html="data.item.phone" />
                        <v-list-item-subtitle v-html="data.item.email" />
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        small
                        class="ml-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="onGroupChat"
                        :disabled="profileType != 'Admin'"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    Multiple Message
                  </v-tooltip>
                  <v-divider />
                </div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item-group v-model="selected" active-class="pink--text">
            <chat-list-item
              v-for="item in conversations"
              :item="item"
              :key="item.header"
            />
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <div
        style="background-color: lightgrey; width: 1px"
        class="hidden-sm-and-down"
      />
      <v-card width="100%" class="hidden-sm-and-down">
        <router-view />
      </v-card>
    </v-card>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Menu from "../../components/core/Menu.vue";
import io from "socket.io-client";
import EditableAvatar from "../../components/core/EditableAvatar.vue";
import ChatListItem from "./ChatListItem.vue";
var socket = io.connect(process.env.VUE_APP_SOCKET_URL);
export default {
  components: { Menu, EditableAvatar, ChatListItem },
  data() {
    return {
      selected: [],
      searchKey: "",
      searchError: null,
      users: [],
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      getAllConversations: "chat/fetchAllConversations",
      updateConversation: "chat/updateConversation",
      getOrganizations: "partner/fetchAllOrganizations",
      getClients: "client/fetchAllClients",
      createConversation: "chat/createNewConversation",
    }),
    searchFilter(item, queryText) {
      const searchText = queryText.toLowerCase();
      if (
        Object.keys(item).some(
          (k) => item[k] && `${item[k]}`.toLowerCase().includes(searchText)
        )
      ) {
        return true;
      }
      if (`{item.first_name + item.last_name}`.includes(searchText)) {
        return true;
      }
      return false;
    },
    onSelectedUser(item) {
      this.loading = true;
      this.createConversation({
        user_id: item._id,
        user_type: item.user_type,
      })
        .then((d) => {
          console.log(d);
          if (this.$vuetify.breakpoint.smAndDown) {
            this.$router.push({
              name: "mchat-details",
              params: { id: d._id },
            });
          } else {
            this.$router.push({
              name: "chat-details",
              params: { id: d._id },
            });
          }

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            console.log(error.response.data.message);
          } else {
            console.log(error);
          }
        });
    },
    getAllUsers() {
      if (this.profileType == "Admin") {
        this.loading = true;
        this.users = [];
        this.getOrganizations()
          .then((data) => {
            data.map((d) => {
              this.users.push({ ...d, user_type: "Organization" });
            });
            this.loading = false;
            this.getClients()
              .then((clients) => {
                clients.map((d) => {
                  this.users.push({ ...d, user_type: "Client" });
                });
                this.loading = false;
              })
              .catch((error) => {
                this.loading = false;
                console.log(error);
              });
          })
          .catch((error) => {
            this.loading = false;
            console.log(error.response.data.message);
          });
      }
    },
    onGroupChat() {
      this.$router.push({ name: "group-chat" });
    },
  },
  computed: {
    ...mapGetters({
      conversations: "chat/getAllConversations",
      profileType: "auth/getType",
    }),
  },
  watch: {
    selected(newValue) {
      if (newValue != undefined) {
        if (
          this.conversations.length > newValue &&
          this.conversations[newValue] &&
          this.$route.params.id != this.conversations[newValue]._id
        ) {
          console.log("watch selected", newValue);
          if (this.$vuetify.breakpoint.smAndDown) {
            this.$router.push({
              name: "mchat-details",
              params: { id: this.conversations[newValue]._id },
            });
          } else {
            this.$router.push({
              name: "chat-details",
              params: { id: this.conversations[newValue]._id },
            });
          }
        }
      } else {
        this.$router.push({ name: "chat" });
      }
    },
  },
  created() {
    socket.on(`newMessage-${this.$route.params.id}`, (data) => {
      if (this.conversations.some((c) => c._id == data.conversation_id)) {
        this.updateConversation(data);
      } else {
        this.getAllConversations();
      }
    });
  },
  mounted() {
    if (this.$route.name == "group-chat") return;
    this.loading = true;
    this.getAllConversations()
      .then(() => {
        this.loading = false;
        this.getAllUsers();
        if (this.$route.params.id == "inbox") {
          if (!this.$route.query._id || !this.$route.query.user_type) {
            this.$router.push({
              name: "chat",
            });
          } else {
            this.onSelectedUser({
              _id: this.$route.query._id,
              user_type: this.$route.query.user_type,
            });
          }
        } else if (this.$route.params.id) {
          this.selected = [];
          for (var i = 0; i < this.conversations.length; i++) {
            var c = this.conversations[i];
            if (c._id == this.$route.params.id) {
              this.selected.push(i);
            }
          }
        } else if (this.conversations.length > 0) {
          if (!this.$vuetify.breakpoint.smAndDown) {
            this.selected = [0];
          }
        } else if (this.profileType != "Admin") {
          this.createConversation()
            .then((res) => {
              if (this.$vuetify.breakpoint.smAndDown) {
                this.$router.push({
                  name: "mchat-details",
                  params: { id: res._id },
                });
              } else {
                this.$router.push({
                  name: "chat-details",
                  params: { id: res._id },
                });
              }
            })
            .catch((error) => {
              if (error.response) {
                console.log(error.response.data.message);
              } else {
                console.log(error);
              }
            });
        }
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          if (error.response) console.log(error.response.data.message);
          else {
            console.log(error);
          }
        }
      });
  },
};
</script>
<style>
.notranslate {
  transform: none !important;
}
</style>
