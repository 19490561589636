var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"padding":"0px","height":"100%","flex-grow":"1","flex-shrink":"0","display":"flex","flex-direction":"column"},attrs:{"fluid":""}},[_c('Menu',{staticStyle:{"height":"50px","flex-grow":"0","background":"#DBC7FF"}}),_c('v-card',{staticStyle:{"flex-grow":"1","flex-shrink":"0","display":"flex","flex-direction":"row","height":"100px","min-height":"100px","overflow":"hidden"}},[_c('v-navigation-drawer',{attrs:{"permanent":"","left":"","width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : 400}},[_c('v-list',{attrs:{"three-line":""}},[(_vm.profileType == 'Admin')?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('div',{staticClass:"ma-3 d-flex flex-row"},[_c('v-autocomplete',{staticClass:"notranslate",attrs:{"disabled":_vm.loading || _vm.profileType != 'Admin',"items":_vm.users,"dense":"","rounded":"","outlined":"","solo":"","background-color":"white","label":"Select","filter":_vm.searchFilter,"error-messages":_vm.searchError,"append-icon":"mdi-magnify"},on:{"change":_vm.onSelectedUser},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.first_name + (data.item.last_name ? " " + data.item.last_name : ""))+" ")]}},{key:"item",fn:function(data){return [_c('v-list-item-avatar',[_c('editable-avatar',{attrs:{"image":data.item.image,"size":40,"editable":false}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(
                          data.item.name
                            ? data.item.name
                            : data.item.first_name +
                              (data.item.last_name
                                ? ' ' + data.item.last_name
                                : '')
                        )}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(data.item.phone)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(data.item.email)}})],1)]}}],null,false,1796784061)}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"fab":"","small":"","disabled":_vm.profileType != 'Admin'},on:{"click":_vm.onGroupChat}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,781149828)},[_vm._v(" Multiple Message ")]),_c('v-divider')],1)])],1)],1):_vm._e(),_c('v-list-item-group',{attrs:{"active-class":"pink--text"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},_vm._l((_vm.conversations),function(item){return _c('chat-list-item',{key:item.header,attrs:{"item":item}})}),1)],1)],1),_c('div',{staticClass:"hidden-sm-and-down",staticStyle:{"background-color":"lightgrey","width":"1px"}}),_c('v-card',{staticClass:"hidden-sm-and-down",attrs:{"width":"100%"}},[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }